<template>
  <div class="pa-4">
    <support-navigation
        @input="handleNewChat"
        title="support.chat.create.title"
        :items="chatTypes"
    />
  </div>
</template>

<script>
import supportNavigation from "@/modules/tech_support/components/UI/supportNavigation";
export default {
  name: "chatSupportCreate",
  props : {
    chatTypes : {
      type : Array,
      default() { return [] }
    }
  },
  components : {
    supportNavigation
  },
  computed : {
    triggerSupportChatType() {
      return this.$store.state.support.triggerSupportChatType
    }
  },
  watch : {
    triggerSupportChatType(value) {
      if ( !value ) {
        return
      }
      this.handleNewChat(value)
    }
  },
  methods : {
    handleNewChat(type) {
      let chatTitle = this.chatTypes.find(el => el.value === type).text
      this.$emit('new-chat' , {type, chatTitle})
      this.$store.state.support.triggerSupportChatType = null
    }
  }
}
</script>

<style scoped>

</style>