<template>
  <div>
    <v-hover  v-for="(item, i ) in itemsFiltered" :key="i" #default="{hover}">
      <v-sheet
          @click="$emit('select' , item)"
          class="d-flex flex-row align-center pointer px-2 py-3 "
          v-ripple
          :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
          :color="hover ? wsLIGHTCARD : null"
      >
        <v-icon :color="item.has_updates ? wsATTENTION : wsDARKLIGHT">mdi-circle-medium</v-icon>

        <div class="flex-grow-1 pr-2">
          <h5 class="wsDARKER shorten-text" style="word-break: break-all !important"
              :class="[{'font-weight-regular' : !item.has_updates}]"
          >
            {{ item.preview }}
          </h5>
          <h5 class="wsDARKLIGHT font-weight-regular ">{{ getTypeName(item.type) }} • <span>{{ MONTH_DAY_TIME(item.date , true , true )  }}</span></h5>
        </div>

        <v-icon :color="wsACCENT">mdi-chevron-right</v-icon>



      </v-sheet>
    </v-hover>

    <div style="height: 68px"></div>


    <div style="position: absolute; bottom : 92px; left: 0; right: 0; padding : 0 12px">
      <ws-button
          @click="()=> { $emit('open-new') }"
          label="support.chat.create.button"
          block
          elevation="2"
      />
    </div>


  </div>
</template>

<script>

export default {
  name: "chatSupportCreate",
  props : {

    items : {
      type : Array,
      default() { return [] },
    },

    chatTypes : {
      type : Array,
      default() { return [] }
    }

  },
  computed : {
    itemsFiltered() {
      if ( this.$store.state.support.displayArchive ) {
        return this.items.filter(el => el.status === 3)
      } else {
        return this.items.filter(el => el.status !== 3)
      }

    }
  },
  methods : {
    getTypeName(type) {
      let chatType = this.chatTypes.find(el => el.value === type)
      return chatType ? chatType.text : type
    }
  }
}
</script>

<style scoped>

</style>